import React from 'react';

import { CheckCircleOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import Category from 'components/Category';
import QuoteId from 'components/QuoteId';

import { quoteSorter } from 'lib/sorters';

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'id',
    align: 'center',
    render: (index) => (

        <QuoteId id={index+1}/>
    ),
    sorter: quoteSorter( 'index' ),
    defaultSortOrder: 'descend',
    width: 60
  },
  {
    title: 'Quote',
    dataIndex: 'quote',
    key: 'quote',
    ellipsis: false,
    width: '40%',
    sorter: quoteSorter( 'quote' ),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    ellipsis: true,
    width: '15%',
    sorter: quoteSorter( 'source' ),
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories',
    render: (categories = []) => (

        <span>
          {
            categories.map( ( category, index ) => (

                <Category
                  name={category}
                  key={category + index}
                  className="quote-cat"
                />
              )
            )
          }
        </span>
    ),
    width: '20%',
  },
  {
      title: 'Review',
      dataIndex: 'review',
      width: 90,
      key: 'review',
      align: 'center',
      render: (review,record) => {

        return (
          review === true && <CheckCircleOutlined/>
        );
      },

      filters: [{ text: 'Review', value: true}],
      filterIcon: (filtered) => (<CheckCircleOutlined style={{color: filtered ? '#1791FF' : '#BFBFBF'}}/>),
      onFilter: (value, record) => record.review === value
  },
  {
      title: 'Hidden',
      dataIndex: 'hide',
      width: 90,
      key: 'hide',
      align: 'center',
      render: (hide,record) => {

        return (
          hide === true && <EyeInvisibleOutlined/>
        );
      },

      filters: [{ text: 'Hide', value: true}],
      filterIcon: (filtered) => (<EyeInvisibleOutlined style={{color: filtered ? '#1791FF' : '#BFBFBF'}}/>),
      onFilter: (value, record) => record.hide === value
  }
];

export default columns;
