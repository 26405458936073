import React, { useMemo } from 'react';

import { List, ListView, SearchBar } from 'antd-mobile';

import CategoryAvatar from 'components/CategoryAvatar';

import { useDispatch, useSelector } from 'react-redux';

import { searchQuotes } from 'actions/quotes-page';

import { selectQuotesLoading, selectQuotesWithSearch } from 'selectors/quotes';

import { quoteSorter } from 'lib/sorters';

import PageWithNav from './PageWithNav';

import './style.css'

const sorter = quoteSorter( 'index', 'descending' );

const { Brief } = List.Item;

//<PlusOutlined />
const QuotesPage = ({history}) => {

  const dispatch = useDispatch();

  const { quotes, searchText } = useSelector( selectQuotesWithSearch );

  const isLoading = useSelector( selectQuotesLoading );

  const dataSource = useMemo( () => {

      const ds = new ListView.DataSource({rowHasChanged: (r1, r2) => r1 !== r2});

      return ds.cloneWithRows( [ ...quotes ].sort( sorter ) );
    }, [quotes] );

  const handleAdd = () => {

    history.push( '/quotes/new' );
  };

  return (
    <PageWithNav
      title="Quotes"
      rightContent={<div role="button" onClick={handleAdd}>Add</div>}
    >
      {isLoading && <div>Loading...</div>}
      {!isLoading &&
        <React.Fragment>
          <SearchBar
            placeholder="Search"
            cancelText="Cancel"
            value={searchText}
            onChange={(value) => { console.log( 'updating search' ); dispatch( searchQuotes( value ) )}}
          />
          <ListView
            style={{width: '100%', height: '100%'}}
            dataSource={dataSource}
            renderRow={(rowData, sectionID, rowID, highlightRow) => {

              if( rowData.loading ) {

                return <div>Loading...</div>;
              }

              const quote = rowData;

              return (

                <List.Item wrap arrow="horizontal" thumb={<CategoryAvatar quote={quote}/>} onClick={() => history.push( '/quotes/' + quote.id )}>
                  {quote.quote}
                  <Brief>{quote.source}</Brief>
                </List.Item>
              );
            }}
            initialListSize={100}
            scrollRenderAheadDistance={2000}
          />
        </React.Fragment>
      }
    </PageWithNav>
  );
}

export default QuotesPage;
