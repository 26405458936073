import React from 'react';

import Category from 'components/Category';

const columns = [
  {
    title: 'Quote',
    dataIndex: 'quote',
    key: 'quote',
    ellipsis: false,
    width: '40%',
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    ellipsis: true,
    width: '15%',
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories',
    render: (categories = []) => (

        <span>
          {
            categories.map( ( category, index ) => (

                <Category
                  name={category}
                  key={category + index}
                  className="quote-cat"
                />
              )
            )
          }
        </span>
    ),
    width: '20%',
  },
];

export default columns;
