import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';

import axios from 'axios';

import { setLatestVersion, updateDeploymentProgress, endDeployment, DEPLOYMENT_UPDATE_LATEST_VERSION, DEPLOYMENT_START } from 'actions/deployment';

import { deployQuotes } from 'lib/api';

const QUOTES_URL = 'https://s3.amazonaws.com/kq.openurise.com/.latest/quotes';

async function getLatestAvailableVersion() {

  try {

    let { status, data } = await axios.get( QUOTES_URL, { headers: { 'Cache-Control': 'no-cache' } } );

    if( status !== 200 ) {

      throw new Error( `Failed to load, status: ${status}` );
    }

    const { meta = {}, items = [] } = data;

    return {

      version: meta.created || 0,
      itemCount: items.length,
    }
  }
  catch( err ) {

    // can't load it
    console.log( 'cannot get latest info', err );

    return { version: -1, itemCount: 0, message: err.message };
  }
}

function* handleUpdateLatestVersion() {

  const versionInfo = yield call( getLatestAvailableVersion );

  yield put( setLatestVersion( versionInfo ) );
}

function* handleDeployment() {

  try {

    let stage = 'deploying';
    yield put( updateDeploymentProgress( { stage } ) );

    const { success, timestamp, message } = yield call( deployQuotes );

    if( !success  ) {

      throw new Error( message );
    }

    stage = 'verify';
    yield put( updateDeploymentProgress( { stage } ) );

    const versionInfo = yield call( getLatestAvailableVersion );

    yield put( setLatestVersion( versionInfo ) );

    if( versionInfo.version !== timestamp ) {

      throw new Error( 'Version code mismatch' );
    }

    stage = 'finished';
    yield put( updateDeploymentProgress( { stage } ) );

    yield put( endDeployment( { success: true }) );
  }
  catch( err ) {

    yield put( endDeployment( { success: false, message: err.message }) )
  }
}

export default function* deploymentSaga() {

  yield takeLatest( DEPLOYMENT_UPDATE_LATEST_VERSION, handleUpdateLatestVersion );
  yield takeEvery( DEPLOYMENT_START, handleDeployment );
}
