import { handleActions } from './handle-actions';

const INITIAL_STATE = {

  selection: [],
};

const reducer = handleActions( {

    QUOTES_PAGE_SELECTION: (draft, {ids}) => { draft.selection = ids; },

    QUOTES_LOADED: (draft) => { draft.selection = []; },

    QUOTE_SAVE_SUCCESSFUL: (draft) => { draft.selection = []; },

    QUOTES_REMOVED: (draft, {ids}) => { draft.selection = draft.selectionfilter( (id) => !ids.includes( id ) ); }
  },
  INITIAL_STATE
);

export default reducer;
