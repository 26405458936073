import { handleActions } from './handle-actions';

const INITIAL_STATE = {

  version: 0,
  itemCount: 0,
  updating: false,
  deploying: false,
};

const reducer = handleActions( {

    DEPLOYMENT_UPDATE_SET_VERSION: (draft, {versionInfo} ) => {

      draft.version = versionInfo.version || 0;
      draft.itemCount = versionInfo.itemCount || 0;
      draft.message = versionInfo.message || '';
      draft.updating = false;
    },

    DEPLOYMENT_UPDATE_LATEST_VERSION: (draft) => {

      draft.updating = true;
      delete draft.message;
    },

    DEPLOYMENT_START: (draft) => {

      draft.deploying = true;
    },

    DEPLOYMENT_PROGRESS: (draft, {progress}) => {

      draft.deployProgress = progress;
    },

    DEPLOYMENT_END: (draft, {result}) => {

      draft.deployResult = result;
      delete draft.deployProgress;
      draft.deploying = false;
    }
  },
  INITIAL_STATE
);

export default reducer;
