import { createSelector } from 'reselect';

const getDeploymentState = state => state.deployment;
const getUpdatingState = state => state.deployment.updating;
const getDeployingState = state => state.deployment.deploying;
const getDeploymentProgress = state => state.deployment.deployProgress;
const getDeploymentResult = state => state.deployment.deployResult;

export const selectVersionInfo = createSelector(

  getDeploymentState,
  ({version = 0,itemCount = 0,message = ''}) => ({version,itemCount,message})
);

export const selectIsUpdating = createSelector(

  getUpdatingState,
  (updating) => (updating === true)
);

export const selectIsDeploying = createSelector(

  getDeployingState,
  (deploying) => (deploying === true)
);

export const selectDeploymentProgress = createSelector(

  getDeploymentProgress,
  (progress = {} ) => progress
);

export const selectDeploymentResult = createSelector(

  getDeploymentResult,
  (result) => result
);
