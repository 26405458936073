export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';

export function createNotification( group, message, details = {}) {

  return { type: NOTIFICATION_CREATE, group, message, details };
};

export function createErrorNotification( message, details = {}) {

  return { type: NOTIFICATION_CREATE, group: 'error', message, details };
};
