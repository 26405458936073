import React from 'react';

//import { MainMobile as App } from './pages/Main';

import { Switch, Route, Redirect } from "react-router-dom";

import { AddQuotePage, QuotePage, QuotesPage } from './pages/mobile';

import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'

const BrowserApp = (props) => {

  return (
    <Switch>
      <Route path="/quotes/new" component={AddQuotePage}/>
      <Route path="/quotes/:id" component={QuotePage}/>
      <Route path="/quotes" component={QuotesPage}/>
      <Route path="*">
        <Redirect to="/quotes"/>
      </Route>
    </Switch>
  );
};

export default BrowserApp;
