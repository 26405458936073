import React from 'react';

import { Button, Card,  List, WingBlank, WhiteSpace } from 'antd-mobile';

import Avatar from 'react-avatar';

import { Auth } from 'aws-amplify';

import { useSelector, useDispatch } from 'react-redux';

import { clearCurrentUser } from 'actions/amplify';

import { selectCurrentUser } from 'selectors/amplify';

import './style.css';

const Sidebar = () => {

  const dispatch = useDispatch();

  const currentUser = useSelector( selectCurrentUser );

  const { attributes: { name, email } } = currentUser;

  const handleSignOut = async () => {

    try {

      await Auth.signOut();

      dispatch( clearCurrentUser() );
    }
    catch( err ) {

      console.log( err );
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
      <div>
        <div style={{padding: 8, backgroundColor: 'black', color: 'white', fontSize: 18, fontWeight: '600'}}>KQ Manager</div>
        <List>
          <List.Item key="quotes">Quotes</List.Item>
        </List>
      </div>
      <div>
        <WingBlank>
          <Card>
            <Card.Header
              title={name}
              thumb={<Avatar email={email} name={name} size={24} round style={{lineHeight: 1, marginRight: 8}}/>}
            />
            <Card.Body>
              <Button size="small" type="warning" onClick={handleSignOut}>Sign Out</Button>
            </Card.Body>
          </Card>
        </WingBlank>
        <WhiteSpace/>
      </div>
    </div>
  );
};

export default Sidebar;
