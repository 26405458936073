/**
 * Actions for Quotes
 */

// Request to load quotes
export const QUOTES_LOAD = 'QUOTES_LOAD';

// quotes were loaded
export const QUOTES_LOADED = 'QUOTES_LOADED';

// Quotes were updated
export const QUOTES_UPDATED = 'QUOTES_UPDATED';

export function loadQuotes()  {

  return { type: QUOTES_LOAD };
}

export function quotesUpdated( quotes, op = 'merge' ) {

  return { type: QUOTES_UPDATED, quotes, op };
}

export function quotesLoaded( quotes ) {

  return { type: QUOTES_LOADED, quotes };
}
