import React from 'react';

import { Tag } from 'antd';

import QuoteId from 'components/QuoteId';

const typeToColor = {

  error: 'red',
  warning: 'orange',
  info: 'blue',
};

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    render: (type) => (<Tag color={typeToColor[type]}>{type.toUpperCase()}</Tag>),
    width: 60
  },
  {
    title: '#',
    dataIndex: 'quote.index'.split( '.' ),
    key: 'index',
    align: 'center',
    render: (index) => (

        <QuoteId id={index+1}/>
    ),
    width: 60
  },
  {
    title: 'Quote',
    dataIndex: 'quote.quote'.split( '.'),
    key: 'quote',
    ellipsis: false,
    width: '40%',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    ellipsis: true,
    width: '20%',
  },
];

export default columns;
