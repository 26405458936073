import React from 'react';

import { Button, Drawer } from 'antd';

import { useWindowWidth } from '@react-hook/window-size'

const EditDrawer = ( {

    onCancel,
    onPrimaryAction,

    extraButtonContent,
    cancelLabel = 'Cancel',
    primaryLabel = 'Save',
    primaryLabelSaving = 'Saving...',
    saving = false,

    width = 'auto',

    children,
    ...rest
  }
) => {

  let windowWidth = useWindowWidth();

  if( width === 'auto' ) {

    width = Math.max( windowWidth * 0.4, 350 );
  }

  return (

    <Drawer
      onClose={onCancel}
      drawerStyle={{background: '#FAFAFA'}}
      bodyStyle={{background: '#FAFAFA', paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 80}}
      maskClosable={false}
      width={width}
      {...rest}
    >

      {children}

      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {extraButtonContent}
        </div>
        <div>
          <Button
            disabled={saving}
            onClick={onCancel}
            style={{ marginRight: 8 }}
          >
            {cancelLabel}
          </Button>
          <Button
            type="primary"
            loading={saving}
            onClick={onPrimaryAction}
          >
            { saving ? primaryLabelSaving : primaryLabel }
          </Button>
        </div>
      </div>

    </Drawer>
  );
};

export default EditDrawer;
