import { categories as allCategories } from 'lib/categories';

import { localeCompare } from 'lib/sorters';

const typeRanking = {

  error: 0,
  warning: 1,
  info: 2,
};

const issueTypeComparator = ( a, b ) => {

  if( a === b ) {

    return 0;
  }

  return typeRanking[ a ] - typeRanking[ b ];
}

const makeIssue = (type, quote, message) => {

  return { type, quote, message };
};

const error = ( quote, message ) => {

  return makeIssue( 'error', quote, message );
}

const warning = ( quote, message ) => {

  return makeIssue( 'warning', quote, message );
}

const info = ( quote, message ) => {

  return makeIssue( 'info', quote, message );
}

export function checkData( quotes ) {

  const issues = [];

  const categories = new Set( allCategories().map( ({name}) => name ) );

  quotes.forEach( (quote) => {

    if( !quote.categories || quote.categories.length === 0 ) {

      issues.push( error( quote, 'Missing categories' ) );
    }

    if( quote.categories && quote.categories.length > 3 ) {

      issues.push( warning( quote, 'More than 3 categories present' ) );
    }

    if( quote.review ) {

      issues.push( info( quote, 'Requires review' ) );
    }

    if( quote.hide ) {

      issues.push( info( quote, 'Marked as hidden' ) );
    }

    if( quote.quote.length > 350 && !quote.hide ) {

      issues.push( warning( quote, `Quote exceeds 350 characters: ${quote.quote.length}` ) );
    }

    if( !quote.source || quote.source.length === 0 ) {

      issues.push( error( quote, 'Source is missing' ) );
    }

    if( quote.categories ) {

      quote.categories.forEach( (cat) => {

        if( !categories.has( cat ) ) {

          issues.push( error( quote, `Category "${cat}" is not valid` ) );
        }
      });
    }
  });

  issues.sort( (a,b) => {

    let result = issueTypeComparator( a.type, b.type );

    if( result === 0 ) {

      result = localeCompare( a.quote.id, b.quote.id );
    }

    return result;
  });

  const counts = {

    error: 0,
    warning: 0,
    info: 0,
  };

  issues.forEach( ({type}) => counts[type] = (counts[type] || 0) + 1 );

  return { issues, counts };
}
