import React from 'react';

import { Button, Modal, Table } from 'antd';

import { useSelector, useDispatch } from 'react-redux';

import { startEditMode } from 'actions/quote-editor';

import { selectGetQuoteEditMode } from 'selectors/quote-editor';

import { selectDeletedQuotes } from 'selectors/deleted';

import defaultColumns from './columns';

import DeleteQuoteModal from 'modal/DeleteQuoteModal';

const DeletedPage = () => {

  const quotes = useSelector( selectDeletedQuotes );

  const dispatch = useDispatch();

  const editMode = useSelector( selectGetQuoteEditMode );

  const columns = [

    ...defaultColumns,
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <Button
          type="link"
          onClick={() => dispatch( startEditMode( id, 'restore' ) )}
        >
          Restore
        </Button>
      ),
      width: 80,
    }
  ]

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: 24, paddingTop: 8}}>
      <h2>Deleted Quotes</h2>
      <Table
        dataSource={quotes}
        columns={columns}
        pagination={{position: 'bottom',defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10','20','50','100']}}
        size={'small' }
        bordered={false}
        style={{ background: '#fff', flexGrow: 2, minHeight: 280 }}
        rowKey={record => record.id}
      />
      { editMode === 'restore' && <DeleteQuoteModal mode="restore"/> }
    </div>
  );
};

export default DeletedPage;
