import React from 'react';

import { Form } from 'antd';

import { useSelector, useDispatch } from 'react-redux';

import { updateQuotes, cancelQuoteEdit } from 'actions/quote-editor';

import EditDrawer from 'components/EditDrawer';

import MultiForm from './MultiForm';

import { selectIsSaving, selectEditQuotes  } from 'selectors/quote-editor';

const EditQuoteDrawer = () => {

  const [ form ] = Form.useForm();

  const dispatch = useDispatch();

  const saving = useSelector( selectIsSaving );

  const quotes = useSelector( selectEditQuotes );

  const onSave = async () => {

    try {

      const values = await form.validateFields();

      const updatedQuotes = quotes.map( ({id}) => [ id, values ]);

      dispatch( updateQuotes( updatedQuotes ) );
    }
    catch( err ) {

      // form validation issue
    }
  };

  const onCancel = () => {

    dispatch( cancelQuoteEdit() );
  };

  return (

    <EditDrawer
      title={'Edit Multiple'}
      visible={true}
      onCancel={onCancel}
      onPrimaryAction={onSave}
      saving={saving}
    >

    <div style={{paddingLeft: 20, paddingTop: 10, paddingRight: 20}}>
      <MultiForm form={form}/>
    </div>

    </EditDrawer>
  );
};

export default EditQuoteDrawer;
