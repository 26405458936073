export function localeCompare( a, b, ...additional ) {

  return (a || '').localeCompare( b || '', ...additional );
}

export function numberCompare( a = 0, b = 0 ) {

  return (a - b);
}

export function quoteSorter( field, direction = 'ascending' ) {

  const dir = direction === 'descending' ? -1 : 1;

  switch( field ) {

    case 'quote':
      return (a, b) => {

        let result = localeCompare( a.quote, b.quote, 'en', { ignorePunctuation: true } );

        if( result === 0 ) {

          result = localeCompare( a.id, b.id );
        }

        return result * dir;
      };

    case 'source':
      return (a, b) => {

        let result = localeCompare( a.source, b.source );

        if( result === 0 ) {

          result = localeCompare( a.id, b.id );
        }

        return result * dir;
      };

    case 'id':
      return (a,b) => localeCompare( a.id, b.id ) * dir;

    case 'lastUpdated':
      return (a,b) => {

        let result = (a.lastUpdated || 0) - (b.lastUpdated || 0);

        if( result === 0 ) {

          result = localeCompare( a.id, b.id );
        }

        return result * dir;
      }

    case 'index':
    default:
      return (a,b) => (a.index - b.index) * dir;
  }
}
