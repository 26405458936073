import React from 'react';

import { takeEvery, call } from 'redux-saga/effects';

import { notification } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';

import { NOTIFICATION_CREATE } from 'actions/notifications';

const groupToMessage = {

  error: 'Error',
  info: 'Information',
  warning: 'Warning',
};

const groupIcons = {

  error: (<CloseCircleTwoTone twoToneColor="#FF0000"/>),
}

async function showNotification( notificationParam ) {

  notification.open( notificationParam );
}

function* handleNotification( { group, message } ) {

  const title = groupToMessage[ group ] || 'Notification';

  yield call( showNotification, {
    message: title,
    description: message,
    icon: groupIcons[ group ] || groupIcons.info,
  });
}

export default function* notificationsSaga() {

  yield takeEvery( NOTIFICATION_CREATE, handleNotification );
}
