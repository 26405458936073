import React from 'react';

import { Button } from 'antd';

const QuoteId = ( { id, onClick, ...rest } ) => {

  if( onClick ) {

    return (
      <Button type='link' onClick={onClick} {...rest}>
        {id}
      </Button>
    );
  }


  return (

    <span {...rest}>
      {id}
    </span>
  );
}

export default QuoteId;
