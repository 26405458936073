import React, { useMemo } from 'react';

import { Table } from 'antd';

import { useSelector } from 'react-redux';

import { selectQuotes } from 'selectors/quotes';

import { localeCompare, numberCompare } from 'lib/sorters';

import Category from 'components/Category';

import { determineCategoryCounts } from 'lib/count';

const CatCountPage = ( props ) => {

  const quotes = useSelector( selectQuotes );

  const categoryCountsData = useMemo( () => determineCategoryCounts( quotes ), [ quotes ] );

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a,b) => localeCompare(a.category,b.category),
      render: (category) => (<Category name={category}/>),
      width: 200,
    },
    {
      title: '1st',
      dataIndex: 'primary',
      key: 'primary',
      width: 80,
      align: 'right',
      render: (value) => value || 0,
      sorter: (a,b) => numberCompare( a.primary, b.primary ),
    },
    {
      title: '2nd',
      dataIndex: 'secondary',
      key: 'secondary',
      width: 80,
      align: 'right',
      render: (value) => value || 0,
      sorter: (a,b) => numberCompare( a.secondary, b.secondary ),
    },
    {
      title: '3rd+',
      dataIndex: 'other',
      key: 'other',
      width: 80,
      align: 'right',
      render: (value) => value || 0,
      sorter: (a,b) => numberCompare( a.other, b.other ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 80,
      align: 'right',
      sorter: (a,b) => numberCompare( a.total, b.total ),
      defaultSortOrder: 'descend',
    }
];

  return (

    <div style={{padding: 24, paddingTop: 8}}>
      <h2>Category Counts</h2>
      <Table
        dataSource={categoryCountsData}
        columns={columns}
        pagination={false}
        size="small"
        bordered={false}
        rowKey={record => record.category}
      />
    </div>
  );
}

export default CatCountPage;
