const cleanUpValue = ( value, type, additional = {} ) => {

  if( value === undefined || value === null ) {

    if( type === 'string' ) {

      return '';
    }
    else if( type === 'boolean' ) {

      return false;
    }
    else if( type === 'array' ) {

      return [];
    }
  }
  else if( type === 'string' ) {

    if( additional.trim ) {

      return value.trim();
    }
  }

  return value;
}

class ObjectDiff {

  constructor() {

    this.fields = [];
  }

  stringField( name, trim = true ) {

    this.fields.push( [ name, 'string', { trim } ] );
    return this;
  }

  arrayField( name, subType ) {

    this.fields.push( [ name, 'array', { subType } ] );
    return this;
  }

  booleanField( name ) {

    this.fields.push( [ name, 'boolean' ] );
    return this;
  }

  differences( sourceState, newState ) {

    const deltas = {};

    this.fields.forEach( ([ name, type, additional ]) => {

      const sourceValue = cleanUpValue( sourceState[ name ], type, additional );
      const newValue = cleanUpValue( newState[ name ], type, additional );

      if( type === 'string' || type === 'boolean' ) {

        if( sourceValue !== newValue ) {

          deltas[ name ] = newValue;
        }
      }
      else if( type === 'array' ) {

        const sourceArray = sourceValue;
        const newArray = newValue;

        if( sourceArray.length !== newArray.length ) {

          deltas[ name ] = newArray;
        }
        else {

          const { subType } = additional;

          for( let i = 0; i < sourceArray.length; i++ ) {

            const a = sourceArray[i];
            const b = newArray[i];

            if( subType === 'string' && a !== b ) {

              deltas[ name ] = newArray;
              break;
            }
          }
        }
      }
    });

    return deltas;
  }
}

export default ObjectDiff;
