import React from 'react';

import { Checkbox, Form, Input } from 'antd';

import QuoteAutoComplete from 'components/QuoteAutoComplete';

import SelectCategories from 'components/SelectCategories';

const { TextArea } = Input;

const RULES = {

  quote: [ {message: 'Quote text is required', required: true} ],
  source: [ {message: 'Source is required', required: true} ],
  categories: [ {required: true, message: 'Please select one or more categories'} ],
};

const QuoteForm = ( { initialValues = {}, admin = false, form } ) => {

  return (

    <Form layout="vertical" form={form} initialValues={initialValues}>
      <Form.Item name="quote" label="Text:" rules={RULES.quote}>
        <TextArea rows={8}/>
      </Form.Item>
      <Form.Item name="source" label="Source:" rules={RULES.source}>
        <QuoteAutoComplete field="source"/>
      </Form.Item>
      <Form.Item name="origSource" label="Original Source:">
        <QuoteAutoComplete field="origSource"/>
      </Form.Item>
      <Form.Item name="categories" label="Categories:" rules={RULES.categories}>
        <SelectCategories/>
      </Form.Item>
      {
        // hidden if admin
        admin &&
        <div>
          <Form.Item name="review" valuePropName="checked">
            <Checkbox>Marked for review</Checkbox>
          </Form.Item>
          <Form.Item name="hide" valuePropName="checked">
            <Checkbox>Hide</Checkbox>
          </Form.Item>
        </div>
      }
    </Form>
  );
};

export default QuoteForm;
