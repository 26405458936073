import produce from 'immer';

const createReducer = ( handlerMap, initialState ) => produce( ( draft, action ) => {

    if( handlerMap[ action.type ] ) {

      // todo: update to use payload layer
      const payload = action;

      return handlerMap[ action.type ]( draft, payload );
    }
  }, initialState
);


export const handleActions = ( handlerMap, initialState ) => {

  return createReducer( handlerMap, initialState );
};

export default handleActions;
