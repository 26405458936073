import { createSelector } from 'reselect';

const getAmplifyState = state => state.amplify;

export const selectCurrentUser = createSelector(
  getAmplifyState,
  ({ attributes, signedIn, groups = [] }) => ({ attributes, signedIn, groups })
);

export const selectIsUserAdmin = createSelector(

  selectCurrentUser,
  ({groups}) => (groups).includes( 'admin' )
)
