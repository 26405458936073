import React from 'react';

import { Card, Statistic } from 'antd';

const DatabaseInfo = ( {info, onRefresh} ) => (

  <Card title="Deployed Database" size="small" extra={<a href="#" onClick={onRefresh}>Refresh</a>} style={{ minWidth: 300 }}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{paddingTop: 16, marginRight: 24, minWidth: 200}}>
        <Statistic title="Deployment Date" value={new Date( info.version).toUTCString()}/>
      </div>
      <div style={{paddingTop: 16, marginRight: 24, minWidth: 100}}>
        <Statistic title="Quote Count" value={info.itemCount}/>
      </div>
      <div style={{paddingTop: 16, marginRight: 24, minWidth: 100}}>
        <Statistic title="Version" value={`${info.version}`} formatter={(value) => value}/>
      </div>
    </div>
  </Card>
);

export default DatabaseInfo;
