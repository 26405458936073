import { useState, useEffect } from 'react';

import { selectIsSaving } from 'selectors/quote-editor';

import { updateQuote } from 'actions/quote-editor';

import { useDispatch, useSelector } from 'react-redux';

import { Toast } from 'antd-mobile';

export const useSaveQuote = ( onDone ) => {

  if( !onDone ) {

    throw new Error( 'onDone not provided' );
  }

  const dispatch = useDispatch();

  const [saveMode, setSaveMode] = useState( { saving: false, text: '' } );

  const isSaving = useSelector( selectIsSaving );

  useEffect( () => {

    const { saving, toast } = saveMode;

    if( !saving ) {

      return;
    }

    if( isSaving && !toast ) {

      Toast.loading( saveMode.text, 0 );

      return setSaveMode( { ...saveMode, toast: true });
    }

    if( !isSaving ) {

      Toast.hide();

      setSaveMode( { saving: false, text: '' } );

      onDone();
      return;
    }
  }, [saveMode, isSaving, onDone]);

  const saveQuote = (id, values, message ) => {

    dispatch( updateQuote( id, values ) );
    setSaveMode( { saving: true, text: message || 'Saving...' } );
  }

  return saveQuote;
}
