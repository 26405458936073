import React from 'react';

const PageContent = ({children}) => {

  return (
    <div style={{paddingTop: 45}}>
      {children}
    </div>
  );
};

export default PageContent;
