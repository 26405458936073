
const _categories = [

    { name: 'anger', group: 0 },
    { name: 'control', group: 0 },
    { name: 'fear', group: 0 },

    { name: 'connection', group: 1 },
    { name: 'love', group: 1 },
    { name: 'vulnerability', group: 1 },

    { name: 'happiness', group: 2 },
    { name: 'peace', group: 2 },
    { name: 'present', group: 2 },

    { name: 'ego', group: 3 },
    { name: 'awareness', group: 3 },
    { name: 'spirituality', group: 3 },

    { name: 'acceptance', group: 4 },
    { name: 'feelings', group: 4 },
    { name: 'thinking', group: 4 },

    { name: 'isolation', group: 5 },
    { name: 'suffering', group: 5 },
    { name: 'recovery', group: 5 },

    { name: 'consciousness', group: 6 },
    { name: 'higher power', group: 6 },
    { name: 'soul', group: 6 },
];

const nameToCategory = (function() {

    let name2cat = {};

    for( let cat of _categories ) {

        name2cat[ cat.name ] = cat;
    }

    return name2cat;
}());

export const categoryFromName = (name) => nameToCategory[ name ];

const catColors = [ 'red', 'volcano', 'gold', 'green', 'cyan', 'blue', 'purple' ];

export function getColorName( name ) {

    const cat = categoryFromName( name );

    return cat !== undefined ? catColors[ cat.group ] : 'gray';
}

export const categories = () => [..._categories];
