export const DEPLOYMENT_UPDATE_LATEST_VERSION = 'DEPLOYMENT_UPDATE_LATEST_VERSION';

export const DEPLOYMENT_UPDATE_SET_VERSION = 'DEPLOYMENT_UPDATE_SET_VERSION';

export const DEPLOYMENT_START = 'DEPLOYMENT_START';

export const DEPLOYMENT_PROGRESS = 'DEPLOYMENT_PROGRESS';

export const DEPLOYMENT_END = 'DEPLOYMENT_END';

export const refreshLatestVersion = () => ({ type: DEPLOYMENT_UPDATE_LATEST_VERSION });

export const setLatestVersion = (versionInfo) => ( { type: DEPLOYMENT_UPDATE_SET_VERSION, versionInfo } );

export const startDeployment = () => ({ type: DEPLOYMENT_START });

export const updateDeploymentProgress = (progress) => ( {type: DEPLOYMENT_PROGRESS, progress });

export const endDeployment = (result) => ({ type: DEPLOYMENT_END, result });
