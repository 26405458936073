// search config

export const config = {

  // Configure redux-search by telling it which resources to index for searching
  resourceIndexes: {

    quotes: ({ resources, indexDocument, state }) => {

      for( let item of resources.values() ) {

        const { id, index, quote, origSource, source, categories } = item;

        quote && indexDocument( id, quote );
        source && indexDocument( id, source );
        origSource && indexDocument( id, origSource );
        indexDocument( id, `${index+1}` );

        categories && categories.forEach( cat => cat && indexDocument( id, cat ) );
      }
    },
  },

  // This selector is responsible for returning each collection of searchable resources
  resourceSelector: (resourceName, state) => {

    if( resourceName === 'quotes' ) {

      return state.quotes.items;
    }
  },
};
