import React from 'react';

import { Modal } from 'antd';

import { useSelector, useDispatch } from 'react-redux';

import { cancelQuoteEdit } from 'actions/quote-editor';
import { updateQuotes } from 'actions/quote-editor';

import { selectEditQuotes, selectIsSaving } from 'selectors/quote-editor';

const DeleteQuoteModal = ({mode = 'delete' }) => {

  const dispatch = useDispatch();

  const selectedQuotes = useSelector( selectEditQuotes );

  const saving = useSelector( selectIsSaving );

  const dispatchAction = () => {

    const updates = selectedQuotes.map( ({id}) => [ id, { deleted: mode === 'delete' }] );

    dispatch( updateQuotes( updates ) );
  }

  return (
    <Modal
      title={ mode === 'delete' ? 'Delete' : 'Restore' }
      visible={true}
      onOk={dispatchAction}
      confirmLoading={saving}
      onCancel={() => dispatch( cancelQuoteEdit() )}
    >
      {selectedQuotes.length === 1 &&
        <div>
          {mode === 'delete' && <p>Delete the following quote ?</p>}
          {mode !== 'delete' && <p>Restore the following quote ?</p>}
          <p>{selectedQuotes[0].quote}</p>
        </div>
      }
      {selectedQuotes.length > 1 &&
        <div>
          {mode === 'delete' && <p>Delete selected quotes ?</p>}
          {mode !== 'delete' && <p>Restore selected quotes ?</p>}
        </div>
      }
    </Modal>
  );
};

export default DeleteQuoteModal;
