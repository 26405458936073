import { all } from 'redux-saga/effects'

import quotesSaga from './quotes';
import notificationsSaga from './notifications';
import amplifySaga from './amplify';
import deploymentSaga from './deployment';

export default function* rootSaga() {

  yield all([
    quotesSaga(),
    notificationsSaga(),
    amplifySaga(),
    deploymentSaga(),
  ]);
}
