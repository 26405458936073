import { compose } from 'redux';

// polyfill
if (process.env.NODE_ENV !== 'production' && !Map.prototype.toJSON ) {
  Map.prototype.toJSON = function() {
    var obj = {};
    this.forEach((value, key) => obj[key] = value);
    return obj;
  }
}

export const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      options: { map: true },
    })
    : compose;
