import add from './AddQuoteDrawer';

import edit from './EditQuoteDrawer';

import multi from './EditMultiQuoteDrawer';

export const AddQuoteDrawer = add;

export const EditQuoteDrawer = edit;

export const EditMultiQuoteDrawer = multi;
