import { createSelector } from 'reselect';

const getQuotes = state => state.quotes.items;

const getIsSaving = state => state.quote_editor.saving === true;

const getEditQuoteIds = state => state.quote_editor.ids;

const getQuoteEditingMode = state => state.quote_editor.mode;

const getDeletedQuotes = state => state.deleted.items;

export const selectIsSaving = createSelector(
  getIsSaving,
  (saving) => saving
);

export const selectEditQuotes = createSelector(

  getQuotes,
  getDeletedQuotes,
  getEditQuoteIds,
  (quotes,deletedQuotes,ids) =>
    ids.map( (id) => quotes.get( id ) || deletedQuotes.get( id ) )
       .filter( (q) => q !== undefined )
);

export const selectGetQuoteEditMode = createSelector(

  getQuoteEditingMode,
  (mode) => mode
)
