import React, { useState } from 'react';

import { Button, Card, Drawer, List, NavBar, Icon, WingBlank, WhiteSpace } from 'antd-mobile';

import Avatar from 'react-avatar';

import { MenuOutlined } from '@ant-design/icons';

import Sidebar from './Sidebar';

import './style.css';

const PageWithNav = ({hasDrawer = true, title, children, onBack, onLeftClick, leftContent, rightContent, leftIcon }) => {

  const [open, setOpen ] = useState( false );

  const sidebar = (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
      <div>
        <div style={{padding: 8, backgroundColor: 'black', color: 'white', fontSize: 18, fontWeight: '600'}}>KQ Manager</div>
        <List>
          <List.Item key="quotes">Quotes</List.Item>
        </List>
      </div>
      <div>
        <WingBlank>
          <Card>
            <Card.Header
              title="Richard"
              thumb={<Avatar email={'richardhyatt@me.com'} name={'Richard Hyatt'} size={25} round style={{lineHeight: 1, marginRight: 5}}/>}
            />
            <Card.Body>
              <Button size="small" type="warning" onClick={() => {}}>Sign Out</Button>
            </Card.Body>
          </Card>
        </WingBlank>
        <WhiteSpace/>
      </div>
    </div>
  );

  return (

    <div style={{height: '100%', backgroundColor: '##F5F5F9'}}>

      {hasDrawer &&
        <Drawer
           className="my-drawer"
           style={{ minHeight: document.documentElement.clientHeight }}
           enableDragHandle={false}
           contentStyle={{ color: '#A6A6A6'}}
           sidebar={<Sidebar/>}
           open={open}
           onOpenChange={(value) => setOpen( value)}
         >
          <NavBar mode="light" icon={<MenuOutlined/>} onLeftClick={() => setOpen( !open )} rightContent={rightContent}>{title}</NavBar>
          <div style={{height: '100%'}}>
            {children}
          </div>
         </Drawer>
      }
      {!hasDrawer &&
        <React.Fragment>
          <NavBar
            mode="light"
            icon={onBack ? (leftIcon === undefined ? <Icon type="left"/> : leftIcon) : null }
            leftContent={leftContent}
            onLeftClick={() => onLeftClick ? onLeftClick() : onBack()}
            rightContent={rightContent}
            style={{left:0,right:0,top:0,position:'fixed',zIndex: 2}}
          >
            {title}
          </NavBar>
          <div className="content-page" style={{paddingTop: 45}}>
            {children}
          </div>
        </React.Fragment>
      }
    </div>
  )
};

export default PageWithNav;
