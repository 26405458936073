import React, { useCallback, useEffect, useState } from 'react';

import { ActionSheet, Button, List, WingBlank } from 'antd-mobile';

import { NavigationBar, Page, PageContent } from 'mobile-components';

import { useSelector } from 'react-redux';

import { selectQuotesMap } from 'selectors/quotes';

import { selectIsUserAdmin } from 'selectors/amplify';

import Category from 'components/Category';

import QuoteEdit from './QuoteEdit';

import { hasChanged, showDiscardActionSheet, validate } from './helper';

import { useSaveQuote } from './save-quote';

import './style.css'

const QuotePage = ({history, match}) => {

  const quotesMap = useSelector( selectQuotesMap );

  const isAdmin = useSelector( selectIsUserAdmin );

  const { params: { id } } = match;

  const originalQuote = quotesMap.get( id ) || {};

  const [quote, setQuote] = useState( {...originalQuote } );

  const [editMode,setEditMode] = useState( false );

  const updateQuoteValues = (changes) => setQuote( {...quote, ...changes } );

  const goBack = useCallback( () => history.replace( '/quotes' ), [history] );

  const saveQuote = useSaveQuote( goBack );

  useEffect( () => {

    if( !originalQuote.id ) {

      goBack();
    }
  }, [originalQuote,goBack] );

  const handleEditToggle = () => {

    if( !editMode ) {

      setEditMode( true );
      return;
    }

    if( validate( quote ) ) {

      saveQuote( id, quote );
    }
  }

  const { quote: quoteText, source, origSource, categories= [], review, hide } = quote;

  const onLeftClick = () => {

    const restore = () => {

      // restore
      setEditMode( false );
      setQuote( originalQuote );
    };

    if( !hasChanged( originalQuote, quote ) ) {

      return restore();
    }

    showDiscardActionSheet( restore );
  };

  const handleDelete = () => {

    return ActionSheet.showActionSheetWithOptions({
        options: [ 'Confirm Delete', 'Cancel' ],
        cancelButtonIndex: 1,
        destructiveButtonIndex: 0,
        message: 'Are you sure that you want to delete this quote?',
        maskClosable: true,
      },
      (buttonIndex) => {

        if( buttonIndex === 0 ) {     // discard changes

          // delete
          saveQuote( id, { deleted: true }, 'Deleting...' );
        }
      }
    );
  };

  return (
    <Page>
      <NavigationBar
        type={ editMode ? 'none' : 'navigation' }
        title={ editMode ? `Edit Quote (${quote.index+1})` : `Quote (${quote.index+1})` }
        leftContent={ editMode ? "Cancel" : null }
        onBack={goBack}
        onLeftClick={onLeftClick}
        rightContent={<div role="button" onClick={handleEditToggle}>{ editMode ? 'Done' : 'Edit' }</div>}
      />
      <PageContent>

        { editMode &&
          <QuoteEdit quote={quote} onChange={updateQuoteValues} isAdmin={isAdmin}/>
        }

        {!editMode &&
          <React.Fragment>
            <List renderHeader={() => ''}>
              <List.Item wrap>{quoteText}</List.Item>
            </List>
            <List renderHeader={() => 'SOURCE'}>
              <List.Item wrap>{source}</List.Item>
            </List>
            <List renderHeader={() => 'ORIGINAL SOURCE'}>
              <List.Item wrap>{origSource}</List.Item>
            </List>
            <List renderHeader={() => 'CATEGORIES'}>
              {categories.map( (cat, index) => (
                <List.Item key={cat + index} >
                  <Category name={cat}/>
                </List.Item>
              ))}
            </List>

            <List renderHeader={() => ''}>
              <List.Item extra={review ? 'Yes' : 'No'}>
                Review
              </List.Item>
              <List.Item extra={hide ? 'Yes' : 'No'}>
                Hide
              </List.Item>
            </List>
          </React.Fragment>
        }

        { editMode &&
          <div style={{paddingTop: 100}}>
            <WingBlank>
              <Button onClick={handleDelete} type="warning">Delete</Button>
            </WingBlank>
          </div>
        }

        <div style={{height:32}}/>
      </PageContent>
    </Page>
  );
}

export default QuotePage;
