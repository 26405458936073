import React from 'react';

import { List, Typography } from 'antd';

import Category from './Category';

import CategoryAvatar from './CategoryAvatar';

const { Text } = Typography;

const QuoteListItem = ({quote, children, ...rest }) => {

  const { quote: quoteText, origSource, source, categories } = quote;

  return (
    <List.Item {...rest}>
      <List.Item.Meta
        avatar={<CategoryAvatar quote={quote}/>}
        title={quoteText}
        description={
          <div>
            <div>
              <Text type="secondary">{source}</Text>
              {origSource && (origSource !== source) && <Text type="secondary">{` (${origSource})`}</Text>}
            </div>
            <div style={{marginTop: 4}}>
              {
                categories.map( ( category, index ) => (

                    <Category
                      name={category}
                      key={category + index}
                    />
                  )
                )
              }
            </div>
          </div>
        }
      />
      {children}
    </List.Item>
  );
};

export default QuoteListItem;
