import { handleActions } from './handle-actions';

const INITIAL_STATE = {

  items: new Map(),
}

const reducer = handleActions( {

  QUOTES_LOAD: (draft, payload) => { draft.items = new Map() },

  QUOTES_LOADED: (draft, { quotes }) => {

      const newItems = [...quotes].filter( ({deleted}) => deleted );

      draft.items = new Map( newItems.map( (quote, index) => ([quote.id,{ ...quote, index }])) );
    },

  QUOTES_UPDATED: (draft, { quotes }) => {

    for( const quote of quotes ) {

      const { id } = quote;

      if( quote.deleted ) {

        draft.items.set( id, quote );
      }
      else {

        draft.items.delete( id );
      }
    }
  },

}, INITIAL_STATE );

export default reducer;
