import React from 'react';

import { Button, Menu, Input, Dropdown, Modal, Table, Typography } from 'antd';
import { DownOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';

import { loadQuotes } from 'actions/quotes';

import { setSelectedQuotes, searchQuotes } from 'actions/quotes-page';

import { startEditMode, startQuoteAdd, startQuoteEdit, startQuoteEditMultiple } from 'actions/quote-editor';

import { selectQuotesLoading, selectQuotesWithSearch } from 'selectors/quotes';

import { selectSelectedIds } from 'selectors/quotes-page';

import { selectGetQuoteEditMode } from 'selectors/quote-editor';

import { AddQuoteDrawer, EditQuoteDrawer, EditMultiQuoteDrawer } from 'drawers';

import DeleteQuoteModal from 'modal/DeleteQuoteModal';

import columns from './columns';

import './Quotes.css';

const { Title } = Typography;

const { Search } = Input;

const Quotes = () => {

  const dispatch = useDispatch();

  const loading = useSelector( selectQuotesLoading );

  const { quotes } = useSelector( selectQuotesWithSearch );

  const selectedIds = useSelector( selectSelectedIds );

  const editMode = useSelector( selectGetQuoteEditMode );

  const addQuote = () => dispatch( startQuoteAdd() );

  const editQuote = (ids) => {

    if( Array.isArray( ids ) ) {

      if( ids.length > 1 ) {

        return dispatch( startQuoteEditMultiple( ids ) );
      }

      ids = ids[0];
    }

    if( ids ) {

      dispatch( startQuoteEdit( ids ) );
    }
  };

  const nothingSelected = (selectedIds.length === 0);

  const actionsMenu = (

    <Menu>
      <Menu.Item
        key="1"
        disabled={nothingSelected}
        onClick={() => editQuote( selectedIds )}
      >
        Edit
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="2"
        disabled={nothingSelected}
        onClick={() => dispatch( startEditMode( selectedIds, 'delete' ) )}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

    // rowSelection object indicates the need for row selection
  const rowSelection = {

    onChange: (selectedRowKeys, selectedRows) => {

      dispatch( setSelectedQuotes( selectedRowKeys ) );
    },

    selectedRowKeys: selectedIds,
    columnWidth: 40
  };


    return (

        <div style={{paddingTop: 0, paddingLeft: 8, paddingRight: 8}}>
          <div style={{paddingBottom: 10 }}>
            {false && <Title style={{fontSize: 26, fontWeight: 600, marginBottom: 1, display: 'inline' }}>Quotes</Title>}
            <Search
              placeholder="Search text"
              style={{ width: '30%', paddingLeft: 0 }}
              onChange={(event) => {

                    dispatch( searchQuotes( event.target.value ) );
                }}

                onSearch={ (value) => {
                    dispatch( searchQuotes( value ) );
                  }
              }
              enterButton
              allowClear
              disabled={loading}
            />

            <div style={{float: 'right'}}>

              <Button
                style={{xfloat: 'right', marginRight: 10}}
                icon={<ReloadOutlined/>}
                onClick={()=> {

                  dispatch( loadQuotes() );
                }}
                disabled={loading}
                loading={loading}
              >
              </Button>

              <Button
                style={{marginRight: 10}}
                type="primary"
                icon={<PlusCircleOutlined/>}
                onClick={()=>addQuote()}
                disabled={loading}
              >
                  Add New Quote
              </Button>

              <Dropdown overlay={actionsMenu} trigger={['click']} disabled={loading}>
                <Button
                  disabled={loading}
                >
                  Actions <DownOutlined/>
                </Button>
              </Dropdown>
            </div>
          </div>
          <Table
            dataSource={quotes}
            columns={columns}
            pagination={{position: 'bottom',defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10','20','50','100']}}
            size={'small' }
            bordered={false}
            style={{ background: '#fff', minHeight: 280 }}
            rowKey={record => record.id}
            loading={loading}
            scroll={{ y: true }}
            onChange={() => dispatch( setSelectedQuotes( [] ))}
            onRow={ (record, rowIndex) => {
              return {
                onDoubleClick: event => {

                  editQuote( record.id );
                }
              };
            }}
            rowSelection={rowSelection}
          />

        { editMode === 'edit' && <EditQuoteDrawer/>}
        { editMode === 'add' && <AddQuoteDrawer/>}
        { editMode === 'edit_multi' && <EditMultiQuoteDrawer/>}
        { editMode === 'delete' && <DeleteQuoteModal mode="delete"/>}
        </div>
    );
}

export default Quotes;
