import React, { useState } from 'react';

import { Checkbox, Divider, Form, Switch, Typography } from 'antd';

import QuoteAutoComplete from 'components/QuoteAutoComplete';

import SelectCategories from 'components/SelectCategories';

import { useSelector } from 'react-redux';

import { selectCurrentUser } from 'selectors/amplify';

import './style.css';

const { Text } = Typography;

const EditSection = ({label, children, divider = true, visible = true}) => {

  const [enabled, setEnabled ] = useState( false );

  return visible ? (
    <div>

      {divider && <Divider/>}

      <div className="multi-edit-section">
        <Text>{label}</Text>
        <Switch checked={enabled} onChange={setEnabled}/>
      </div>

      { enabled &&
        <div className="multi-edit-section-item">
          {children}
        </div>
      }
    </div>
  ) : null;
};

const MultiForm = ( { initialValues = { origSource: '' }, form } ) => {

  const currentUser = useSelector( selectCurrentUser );

  const admin = currentUser.groups.includes( 'admin' );

  return (

    <Form layout="vertical" initialValues={initialValues} form={form}>
      <EditSection label="Source" divider={false}>
        <Form.Item name="source" rules={[{message: 'Source is required', required: true }]}>
          <QuoteAutoComplete field="source"/>
        </Form.Item>
      </EditSection>

      <EditSection label="Original Source">
        <Form.Item name="origSource">
          <QuoteAutoComplete field="origSource"/>
        </Form.Item>
      </EditSection>

      <EditSection label="Categories">
        <Form.Item name="categories" rules={[{message: 'Please select one or more categories', required: true}]}>
          <SelectCategories/>
        </Form.Item>
      </EditSection>

      <EditSection label="Review" visible={admin}>
        <Form.Item name="review" valuePropName="checked">
          <Checkbox>Marked for review</Checkbox>
        </Form.Item>
      </EditSection>

      <EditSection label="Hide" visible={admin}>
        <Form.Item name="hide" valuePropName="checked">
          <Checkbox>Hide</Checkbox>
        </Form.Item>
      </EditSection>
    </Form>
  );
};

export default MultiForm;
