import React from 'react';

const Page = ({children}) => {

  return (
    <div
      style={{height: '100%', backgroundColor: '##F5F5F9'}}
    >
      {children}
    </div>
  );
};

export default Page;
