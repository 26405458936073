import { handleActions } from './handle-actions';

const INITIAL_STATE = {

  groups: [],
  attributes: {},
  signedIn: false
};

const reducer = handleActions( {

    AMPLIFY_SET_CURRENT_AUTH_USER: (draft, {groups, attributes} ) => {

      draft.groups = groups;
      draft.attributes = attributes;
      draft.signedIn = true;
    },

    AMPLIFY_CLEAR: (draft) => {

      draft.groups = [];
      draft.attributes = [];
      draft.signedIn = false;
    },
  },
  INITIAL_STATE
);

export default reducer;
