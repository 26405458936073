import React, { useEffect, useState } from 'react';

import { Steps } from 'antd';

import { useSelector } from 'react-redux';

import {  selectDeploymentProgress } from 'selectors/deployment';

const { Step } = Steps;

const DeploymentStatus = () => {

  const [ current, setCurrent ] = useState( 0 );

  const { stage } = useSelector( selectDeploymentProgress );

  useEffect( () => {

    const stages = {

      deploying: 0,
      verify: 1,
      finished: 3,
    }

    if( stages[ stage ] ) {

      setCurrent( stages[ stage ] );
    }
  }, [ stage ])

  return (

    <Steps direction="vertical" current={current}>
      <Step title="Creating" description="Quotes database snapshow created"/>
      <Step title="Verifying" description="Database is verified"/>
      <Step title="Finished" description="Database is deployed"/>
    </Steps>
  );
}

export default DeploymentStatus;
