import React from 'react';

import { Icon, NavBar } from 'antd-mobile';
import { MenuOutlined } from '@ant-design/icons';

const iconForType = (type) => {

  if( type === 'navigation' ) {

    return (<Icon type="left"/>);
  }
  else if( type === 'menu' ) {

    return (<MenuOutlined/>);
  }
  else {

    return undefined;
  }
}

const NavigationBar = ({
  title,
  type = 'navigation',
  onMenu,
  onBack,
  onLeftClick,
  ...rest
  }) => {

  const handleLeftClick = () => {

    if( type === 'menu' && onMenu ) {

      return onMenu();
    }

    if( type === 'navigation' && onBack ) {

      return onBack();
    }

    if( onLeftClick ) {

      return onLeftClick();
    }
  };

  return (
    <NavBar
      mode="light"
      icon={ iconForType(type) }
      onLeftClick={handleLeftClick}
      style={{ left:0,right:0,top:0,position:'fixed',zIndex: 2 }}
      { ...rest }
    >
      {title}
    </NavBar>
  )
};

export default NavigationBar;
