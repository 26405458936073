import React from 'react';

import { Icon, InputItem, List, Picker, Switch, TextareaItem } from 'antd-mobile';

import Category from 'components/Category';

import { categories as allCategories } from 'lib/categories';

const { Brief } = List.Item;

const CategoryPicker = ({value, onChange, onDelete }) => (

  <Picker
    title="Select Category"
    dismissText="Cancel"
    okText="Done"
    data={allCategories().map( (c) => ({label: (<Category name={c.name}/>), value: c.name}))}
    cols={1}
    extra={
      value ? <span
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          if( onDelete ) {

            onDelete();
          }
        }}
      >
        <Icon type="cross-circle" color="red"/>
      </span> : <span/>
    }
    value={[value]}
    onChange={([updateValue]) => onChange( updateValue )}
    format={() => null}
  >
    <List.Item>
      {value && <Category name={value}/>}
      {!value && <Brief>Add a Category</Brief>}
    </List.Item>
  </Picker>
);

const QuoteEdit = ({quote, onChange, isAdmin }) => {

  const { quote: quoteText, source, origSource, categories= [], review, hide } = quote;

  return (
    <React.Fragment>
      <List renderHeader={() => ''}>
        <TextareaItem placeholder="Quote Text (Required)" autoHeight value={quoteText} onChange={(value) => onChange( {quote: value})}/>
      </List>
      <List renderHeader={() => 'SOURCE'}>
        <InputItem value={source} placeholder="Required" onChange={(value) => onChange( { source: value })}/>
      </List>
      <List renderHeader={() => 'ORIGINAL SOURCE'}>
        <InputItem value={origSource} onChange={(value) => onChange( { origSource: value })}/>
      </List>
      <List renderHeader={() => 'CATEGORIES'}>
        {categories.map( (cat, index) => (
          <CategoryPicker
            key={cat + index}
            value={cat}
            onChange={(value) => {
              const updatedCategories = [ ...categories ];
              updatedCategories[index] = value;
              onChange( { categories: updatedCategories } );
            }}
            onDelete={() => {
              const updatedCategories = [ ...categories ];
              updatedCategories.splice( index, 1 );
              onChange( { categories: updatedCategories } );
            }}
          />
        ))}
        <CategoryPicker
          onChange={(value) => {
            const updatedCategories = [ ...categories, value ];
            onChange( { categories: updatedCategories } );
          }}
        />
      </List>

      {isAdmin &&
        <List renderHeader={() => ''}>
          <List.Item extra={<Switch checked={review} onChange={(value) => onChange( { review: value })}/>}>
            Review
          </List.Item>
          <List.Item extra={<Switch checked={hide} onChange={(value) => onChange( { hide: value })}/>}>
            Hide
          </List.Item>
        </List>
      }
    </React.Fragment>
  );
}

export default QuoteEdit;
