import React, { useState, useEffect } from 'react';

import Category from 'components/Category';

import { getQuoteHistory } from 'lib/api';

import Moment from 'react-moment';

import { Timeline, Typography } from 'antd';

const { Text } = Typography;

function getOperation( op ) {

  switch( op ) {

    case 'create':
      return 'Created';

    default:
      return 'Updated';
  }
}

const HistoryItem = ({visible, label, value, children}) => {

  if( visible === undefined ) {

    if( children && children.length > 0 ) {

      visible = true;
    }
    else {

      visible = value !== undefined && value !== null;
    }
  }

  return visible ?
    (<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
      <div style={{whiteSpace: 'nowrap'}}>
        <Text>{label} &nbsp;</Text>
      </div>
      <div style={{flexGrow: 2}}>
        {value && <Text type="secondary" style={{textAlign: 'left'}}>{value}</Text>}
        {children}
      </div>
    </div>)
    : null;
}

function QuoteHistory( { id, ...rest } ) {

  const [historyItems, setHistoryItems] = useState( [] );

  useEffect( () => {

    const load = async () => {

      try {

        let { items } = await getQuoteHistory( id );

        if( !items || items.length === 0) {

          items = [ { id: -1, timestamp: -1 } ];
        }

        setHistoryItems( items );
      }
      catch( err ) {

        console.error( err );

        setHistoryItems( [ {id: -2}] );
      }
    };

    load();
  },
  [id, setHistoryItems]);

  return (
    <Timeline pending={historyItems.length === 0 ? 'Loading' : null} reverse {...rest}>
      {
        historyItems.map( (record) => {

          if( record.id === -1 ) {

            return (

              <Timeline.Item color="red" key="0">
                <Text>No History</Text>
              </Timeline.Item>
            );
          }
          else if( record.id === -2 ) {

            return (

              <Timeline.Item color="red" key="0">
                <Text>Error while loading history</Text>
              </Timeline.Item>
            );
          }

          return (
            <Timeline.Item key={record.id + record.timestamp}>
              <div>
                <Text strong style={{paddingRight: 10}}>
                  {getOperation( record.op )}
                  &nbsp;at&nbsp;
                  <Moment format="HH:mm:ss YYYY-MM-DD" unix>{record.timestamp/1000}</Moment>
                </Text>
                {record.user && <Text type="secondary">{`${record.user}`}</Text>}
              </div>
              <HistoryItem label="Quote Text:" value={record.quote}/>
              <HistoryItem label="Source:" value={record.source}/>
              <HistoryItem label="Original Source:" value={record.origSource}/>
              <HistoryItem label="Categories:">
                {record.categories && record.categories.map( (cat) => <Category name={cat} key={cat}/>)}
              </HistoryItem>
              <HistoryItem label="Review:" visible={record.review !== undefined} value={record.review ? 'Yes' : 'No'}/>
              <HistoryItem label="Hide:" visible={record.hide !== undefined} value={record.hide ? 'Yes' : 'No'}/>
              <HistoryItem label="Deleted:" visible={record.deleted !== undefined} value={record.deleted ? 'Yes' : 'Restored'}/>
            </Timeline.Item>
          )
        })
      }
    </Timeline>
  )
}

export default QuoteHistory;
