import React, { useEffect, useState } from 'react';

import { Alert, Button, Modal, Result, Spin } from 'antd';
import { ExclamationCircleOutlined, FrownOutlined } from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';

import { refreshLatestVersion, startDeployment} from 'actions/deployment';

import { selectIsUpdating, selectVersionInfo, selectIsDeploying, selectDeploymentResult } from 'selectors/deployment';
import { selectIsUserAdmin } from 'selectors/amplify';

import DatabaseInfo from './DatabaseInfo';
import DeploymentStatus from './DeploymentStatus';

const { confirm } = Modal;

const DeploymentPage = () => {

  const [isShowDeployment, setShowDeployment] = useState( false );

  const dispatch = useDispatch();

  const isDeploying = useSelector( selectIsDeploying );

  const deploymentResult = useSelector( selectDeploymentResult );

  const isUpdating = useSelector( selectIsUpdating );

  const versionInfo = useSelector( selectVersionInfo );

  const isAdmin = useSelector( selectIsUserAdmin );

  const doRefresh = () => dispatch( refreshLatestVersion() );

  // automatically refresh the deployed version info
  useEffect( () => {

    // only do this on the first page load
    if( !isDeploying && versionInfo.version <= 0 ) {

      doRefresh();
    }
  }, []);

  // automatically transition to deployment mode
  useEffect( () => {

    if( isDeploying && !isShowDeployment ) {

      setShowDeployment( true );
    }
  }, [isDeploying, isShowDeployment] );

  const confirmDeployment = () => {

    confirm(
      {
      title: 'Do you want to deploy?',
      content: 'This operation will deploy quotes to the cloud for use by the KQ App',
      icon: <ExclamationCircleOutlined/>,
      onOk: () => {

        dispatch( startDeployment() );
      },
      onCancel: () => {}
    });
  };

  return (

    <div style={{padding: 24, paddingTop: 8}}>

      { isShowDeployment &&
        <div>
          <h2>Deploying...</h2>
          <DeploymentStatus/>
          {!isDeploying &&
            <div style={{paddingTop: 16}}>
              <Alert
                message={deploymentResult.success ? "Success" : "Error"}
                description={
                    deploymentResult.success ? "The deployment was successful"
                      : `Failed to deploy: ${deploymentResult.message}` }
                type={ deploymentResult.success ? "success" : "error" }
                showIcon
              />
              <div style={{paddingTop: 32}}>
                <Button type="primary" onClick={() => setShowDeployment( false )}>Close</Button>
              </div>
            </div>
          }
        </div>
      }

      { !isShowDeployment &&
        <div>
          { isUpdating &&
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 300}}>
              <Spin size="large"/>
            </div>
          }
          { !isUpdating && versionInfo.version >= 0 && <DatabaseInfo info={versionInfo} onRefresh={doRefresh}/>}
          { !isUpdating && versionInfo.version < 0 &&
            <Result
              icon={<FrownOutlined style={{color: 'red'}}/>}
              title="Cannot refresh version information"
              subTitle={ versionInfo.message }
              extra={<Button type="primary" onClick={doRefresh}>Refresh</Button>}
            />
          }
          {
            isAdmin && !isUpdating && versionInfo.version >= 0 &&
            <div style={{marginTop: 32}}>
              <Button type="primary" danger onClick={confirmDeployment}>Deploy Now</Button>
            </div>
        }
        </div>
      }
    </div>
  );
};

export default DeploymentPage;
