import React from 'react';

import { Tag } from 'antd';

import { getColorName } from 'lib/categories';

const Category = ( { name, ...rest } ) => (

    <Tag color={getColorName(name)} key={name} {...rest}>
        {name.toUpperCase()}
    </Tag>
);

export default Category;
