import React from 'react';

import { Button, Collapse, Form, Modal } from 'antd';

import { useSelector, useDispatch } from 'react-redux';

import { updateQuote, cancelQuoteEdit } from 'actions/quote-editor';

import EditDrawer from 'components/EditDrawer';

import QuoteHistory from '../QuoteHistory';

import QuoteForm from '../QuoteForm';

import { selectIsSaving, selectEditQuotes  } from 'selectors/quote-editor';

import { selectCurrentUser } from 'selectors/amplify';

const { Panel } = Collapse;

const EditQuoteDrawer = () => {

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const saving = useSelector( selectIsSaving );

  const [ quote ] = useSelector( selectEditQuotes );

  const currentUser = useSelector( selectCurrentUser );

  const isAdmin = currentUser.groups.includes( 'admin' );

  const { id, index } = quote;

  const onSave = async () => {

    try {

      const updates = await form.validateFields();

      dispatch( updateQuote( quote.id, updates ) );
    }
    catch( err ) {

      // ignore
    }
  };

  const onCancel = () => {

    dispatch( cancelQuoteEdit() );
  };

  const onDelete = () => {

    Modal.confirm({

      title: 'Delete',
      content: 'Delete this quote ?',
      okText: 'Yes',
      onOk: () => new Promise( (resolve) => {
        dispatch( updateQuote( quote.id, { deleted: true } ) );
        resolve();
      })
    });
  }

  return (

    <EditDrawer
      title={
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 24}}>
          <div>
            {`Quote #: ${index + 1}`}
          </div>
          <div style={{fontSize: 14, color: '#777', fontWeight: 300}}>
            {`id: ${id}`}
          </div>
        </div>}
      visible={true}
      onCancel={onCancel}
      onPrimaryAction={onSave}
      saving={saving}
      extraButtonContent={
        <Button
          danger
          onClick={onDelete}
        >
          Delete
        </Button>
      }
    >
      <Collapse defaultActiveKey="details">
        <Panel header="Details" key="details">
          <QuoteForm form={form} initialValues={quote} admin={isAdmin}/>
        </Panel>
        <Panel header="History" key="history">
          <QuoteHistory id={quote.id} style={{padding:0}}/>
        </Panel>
      </Collapse>
    </EditDrawer>
  );
};

export default EditQuoteDrawer;
