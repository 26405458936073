import { ActionSheet, Modal } from 'antd-mobile';

const alert = Modal.alert;

const areStringsDifferent = (a = '',b = '') => {

  if( a === null || b === null ) {

    return a !== b;
  }

  return a.trim() !== b.trim();
};

export const hasChanged = ( originalQuote, editing ) => {

  if( areStringsDifferent( originalQuote.quote, editing.quote ) ||
      areStringsDifferent( originalQuote.source, editing.source ) ||
      areStringsDifferent( originalQuote.origSource, editing.origSource ) ||
      originalQuote.review !== editing.review ||
      originalQuote.hide !== editing.hide ||
      originalQuote.categories.length !== editing.categories.length ) {

    // easy checks for changes - something is different
    return true;
  }

  // categories are the same length
  for( let i = 0; i < originalQuote.categories.length; i++ ) {

    if( originalQuote.categories[i] !== editing.categories[ i] ) {

      return true;
    }
  }

  // nothing is different
  return false;
}

export const showError = (title, message) => {

  alert( title, message, [ { text: 'Close', onPress: () => {} }] );
  return false;
}

export const validate = (quote) => {

  if( quote.quote.trim().length === 0 ) {

    return showError( 'Quote Text', 'Quote text cannot be empty' );
  }

  if( quote.source.trim().length === 0 ) {

    return showError( 'Source', 'Source cannot be empty' );
  }

  if( quote.categories.length === 0 ) {

    return showError( 'Categories', 'Please select one or more categories' );
  }

  // all good
  return true;
}

export const showDiscardActionSheet = ( discardAction ) => {

  ActionSheet.showActionSheetWithOptions({
      options: [ 'Discard Changes', 'Keep Editing' ],
      cancelButtonIndex: 1,
      destructiveButtonIndex: 0,
      message: 'Are you sure that you want to discard your changes?',
      maskClosable: true,
    },
    (buttonIndex) => {

      if( buttonIndex === 0 ) {     // discard changes

        discardAction();
      }
    }
  );
}
