import { Auth } from 'aws-amplify';

import { takeEvery, call, put } from 'redux-saga/effects';

import { AMPLIFY_GET_CURRENT_AUTH_USER, AMPLIFY_CLEAR, setCurrentAuthenticatedUser } from 'actions/amplify';

async function getCurrentAuthUser() {

  return await Auth.currentAuthenticatedUser();
}

function* handleGetCurrentAuthUser() {

  try {

    const data = yield call( getCurrentAuthUser );

    yield put( setCurrentAuthenticatedUser( data ) );
  }
  catch( err ) {

    yield put( { type: AMPLIFY_CLEAR } );
  }
}

export default function* amplifySaga() {

  yield takeEvery( AMPLIFY_GET_CURRENT_AUTH_USER, handleGetCurrentAuthUser );
}
