import React, { useRef } from 'react';

import { Form } from 'antd';

import { useSelector, useDispatch } from 'react-redux';

import EditDrawer from 'components/EditDrawer';

import QuoteForm from '../QuoteForm';

import { selectIsSaving  } from 'selectors/quote-editor';

import { cancelQuoteEdit, saveNewQuote } from 'actions/quote-editor';

const QuoteDrawer = () => {

  const [ form ] = Form.useForm();

  const dispatch = useDispatch();

  const saving = useSelector( selectIsSaving );

  const onAdd = async () => {

    try {

      const values = await form.validateFields();

      dispatch( saveNewQuote( values ) );
    }
    catch( err ) {

      // validation failure
    }
  };

  const onCancel = () => {

    dispatch( cancelQuoteEdit() );
  };

  return (

    <EditDrawer
      title="Add Quote"
      visible={true}
      onCancel={onCancel}
      onPrimaryAction={onAdd}
      saving={saving}
    >

      <div style={{paddingLeft: 20, paddingTop: 10, paddingRight: 20}}>
        <QuoteForm form={form}/>
      </div>

    </EditDrawer>
  );
};

export default QuoteDrawer;
