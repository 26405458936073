/**
 * Actions for Quote Editing
 */

export const QUOTE_EDITOR_START = 'QUOTE_EDITOR_START';

export const QUOTE_SAVE = "QUOTE_SAVE";

export const QUOTES_SAVE = 'QUOTES_SAVE';

export const QUOTE_UPDATE = 'QUOTE_UPDATE';

export const QUOTE_SAVE_ERROR = 'QUOTE_SAVE_ERROR';

export const QUOTE_SAVE_SUCCESSFUL = 'QUOTE_SAVE_SUCCESSFUL';

export const QUOTE_SAVE_UNSUCCESSFUL = 'QUOTE_SAVE_UNSUCCESSFUL';

export const QUOTE_EDITOR_CANCEL = 'QUOTE_EDITOR_CANCEL';

export function startEditMode( ids, mode = 'edit' ) {

  if( !Array.isArray( ids ) ) {

    ids = [ ids ];
  }

  return { type: QUOTE_EDITOR_START, ids, mode };
}

export const startQuoteEdit = ( id ) => startEditMode( [ id ], 'edit' );

export const startQuoteEditMultiple = ( ids ) => startEditMode( ids, 'edit_multi' );

export const startQuoteAdd = () => startEditMode( [], 'add' );


export function updateQuote( id, values ) {

  return { type: QUOTE_SAVE, id, values };
}

export function updateQuotes( updates ) {

  return { type: QUOTES_SAVE, updates };
}

export function saveNewQuote( values ) {

  return { type: QUOTE_SAVE, values };
}

export function cancelQuoteEdit() {

  return { type: QUOTE_EDITOR_CANCEL };
}
