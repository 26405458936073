import { takeEvery, call, put, select } from 'redux-saga/effects';

import { quotesLoaded, quotesUpdated, QUOTES_LOAD } from 'actions/quotes';

import { QUOTE_SAVE, QUOTES_SAVE, QUOTE_SAVE_SUCCESSFUL, QUOTE_SAVE_UNSUCCESSFUL } from 'actions/quote-editor';

import { createErrorNotification } from 'actions/notifications';

import { addQuote, getQuotes, updateQuote } from 'lib/api';

import { selectAllQuotesMap } from 'selectors/quotes'

import ObjectDiff from 'lib/object-diff';

const quoteDiff = new ObjectDiff()
                    .stringField( 'quote' )
                    .stringField( 'source' )
                    .stringField( 'origSource' )
                    .booleanField( 'review' )
                    .booleanField( 'hide' )
                    .booleanField( 'deleted' )
                    .arrayField( 'categories', 'string' );

function* handleLoadQuotes() {

  try {

    const { items } = yield call( getQuotes );

    yield put( quotesLoaded( items ) );
  }
  catch( err ) {

    // log error
    console.error( err );

    yield put( createErrorNotification( `Quotes refresh failed: ${err.message}` ) );
    yield put( quotesLoaded( [] ) );
  }
}

function *doSaveQuotes( updates ) {

  const quotesMap = yield select( selectAllQuotesMap );

  updates = updates.map( ([id, values]) => {

      const quote = quotesMap.get( id );

      if( !quote ) {

        throw new Error( `invalid quote id: ${id}` );
      }

      const updateValues = quoteDiff.differences( quote, { ...quote, ...values } );

      return [id,updateValues];
    })
    .filter( ([,values]) => Object.keys( values ).length > 0 );

  for( let [id,values] of updates ) {

    const { updated } = yield call( updateQuote, id, values );

    yield put( quotesUpdated( [ updated ] ) );
  }
}

function* handleSaveQuote( action ) {

  const { id, values } = action;

  try {

    if( id ) {

      // update
      yield call( doSaveQuotes, [ [id,values] ] );
    }
    else {

      // add
      const { item } = yield call( addQuote, values );

      yield put( quotesUpdated( [ item ] ) );
    }

    yield put( { type: QUOTE_SAVE_SUCCESSFUL } );
  }
  catch( err ) {

    // log error
    console.error( err );

    yield put( createErrorNotification( `Quote update failed: ${err.message}` ) );

    yield put( { type: QUOTE_SAVE_UNSUCCESSFUL } );
  }
}

function* handleSaveQuotes( action ) {

  const { updates } = action;

  try {

    yield call( doSaveQuotes, updates );

    yield put( { type: QUOTE_SAVE_SUCCESSFUL } );
  }
  catch( err ) {

    // log error
    console.error( err );

    yield put( createErrorNotification( `Quote update failed: ${err.message}` ) );

    yield put( { type: QUOTE_SAVE_UNSUCCESSFUL } );
  }
}

export default function* quotesSaga() {

  yield takeEvery( QUOTES_LOAD, handleLoadQuotes );
  yield takeEvery( QUOTE_SAVE, handleSaveQuote );
  yield takeEvery( QUOTES_SAVE, handleSaveQuotes );
}
