function getCategoryCounts( quotes, index = 0, additional = 1 ) {

    const cats = new Map();

    for( let i = 0; i < quotes.length; i++ ) {

      const q = quotes[i];

      const end = Math.min( index + additional, q.categories.length );

      for( let j = index; j < end; j++ ) {

        const cat = q.categories[j];

        const existing = cats.get( cat ) || 0;

        cats.set( cat, existing + 1 );
      }
    }

    return cats;
}

export const determineCategoryCounts = ( quotes ) => {

  const catCounts = {};

  const groups = [ 'primary', 'secondary', 'other' ];

  const additionals = [1,1,100];

  for( let i = 0; i < 3; i++ ) {

    const cats = getCategoryCounts( quotes, i, additionals[i] );

    //console.log( cats );

    for( const [cat,count] of cats.entries() ) {

      let counts = catCounts[ cat ];

      if( !counts ) {

        counts = { category: cat };
        catCounts[ cat ] = counts;
      }

      counts[ groups[i] ] = count;
      counts.total = (counts.total || 0) + count;
    }
  }

  let countsAsArray = Object.values( catCounts );

  countsAsArray.sort( (a,b) => (a.total - b.total) );

  return countsAsArray;
}
