import { createStore, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';

import { reduxSearch } from 'redux-search'

import { composeEnhancers } from './helper';

import rootReducer from './reducers';

import rootSaga from './sagas';

import { config as searchConfig } from './search';

const sagaMiddleware = createSagaMiddleware();

const enhancers = composeEnhancers(

  applyMiddleware( sagaMiddleware ),
  reduxSearch( searchConfig )
);

export function configureStore( preloadedState) {

  const store = createStore(
    rootReducer(),
    preloadedState,
    enhancers
  );

  sagaMiddleware.run( rootSaga )

  return store;
}
