import { API } from 'aws-amplify';

const API_NAME = 'KQManagerAPI';

export async function getQuotes() {

  return await API.get( API_NAME, '/quotes', { } );
}

export async function updateQuote( id, values ) {

  return await API.put( API_NAME, `/quotes/${id}`, {

    body: { ...values },
  });
}

export async function addQuote( values ) {

  return await API.post( API_NAME, '/quotes', {

    body: { ...values },
  });
}

export async function getQuoteHistory( id ) {

  return await API.get( API_NAME, `/quotes/${id}/history`, { } );
}

export async function deployQuotes() {

  return await API.post( API_NAME, '/deployments', {

    body: {}
  });
}
