import React from 'react';

import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { withRouter } from "react-router";

import Avatar from 'react-avatar';

import { useDispatch } from 'react-redux'

import { Dropdown, Layout, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { clearCurrentUser } from 'actions/amplify';

import Home from './pages/Home';
import Quotes from './pages/Quotes';
//import Quotes from '../QuotesNG';
import DataCheck from './pages/DataCheck';
import Tools from './pages/Tools';

import { Auth } from 'aws-amplify';

import preval from 'preval.macro'

import 'antd/dist/antd.css';


const { Header, Footer, Content } = Layout;

const { Text } = Typography;

function getMenuName( routerPathName ) {

  let menuName = routerPathName.slice(1);

  if( menuName.length === 0 ) {

    menuName = 'home';
  }

  return menuName;
}

function MainBrowswer( { authData, history } ) {

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const menuName = getMenuName( pathname );

  const { attributes: { name = '', email = '' } = {} } = authData;

  const userMenu = (
    <Menu>
      {false && <Menu.Item key="profile">Profile</Menu.Item>}
      {false && <Menu.Divider />}
      <Menu.Item key="signOut" onClick={async () => {

        try {

          await Auth.signOut();

          dispatch( clearCurrentUser() );
        }
        catch( err ) {

          console.log( err );
        }
      }}>Sign out</Menu.Item>
    </Menu>
  );

  return (

    <Layout style={{backgroundColor: 'white'}}>
      <Header style={{backgroundColor: '#FAFAFA', display: 'flex', flexDirection: 'column', position: 'fixed', justifyContent: 'space-between', zIndex: 2, width: '100%', height: 110, paddingLeft: 0, paddingRight: 0}}>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 12, backgroundColor: 'black'}}>
          <Text style={{color:'white', fontSize: 24, fontWeight: 700, padding: 0, margin: 0, lineHeight: 1}}>KQ-Manager</Text>
          <Dropdown overlay={userMenu} trigger={['hover','click']} style={{marginRight: 12}} >
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{overflow: 'hidden'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                <Avatar email={email} name={name} size={25} round style={{lineHeight: 1, marginRight: 5}}/>
                <div style={{paddingLeft: 8, lineHeight: 2 }}>
                  <Text style={{color: 'white'}}>{name}</Text> <DownOutlined/>
                </div>
              </div>
            </a>
          </Dropdown>
        </div>
          <Menu mode="horizontal" theme="light" style={{backgroundColor: '#FAFAFA', padding:0, margin: 0, lineHeight: 3}} selectedKeys={[menuName]} onClick={({key}) => history.push( `/${key}`)}>
            <Menu.Item key="home" style={{marginLeft: 10, marginRight: 10}} className="horizonal-menuitem">
              Home
            </Menu.Item>
            <Menu.Item key="quotes" style={{marginLeft: 10, marginRight: 10}} className="horizonal-menuitem">
              Quotes
            </Menu.Item>
            <Menu.Item key="check" style={{marginLeft: 10, marginRight: 10}} className="horizonal-menuitem">
              Data Check
            </Menu.Item>
            <Menu.Item key="tools" style={{marginLeft: 10, marginRight: 10}} className="horizonal-menuitem">
              Tools
            </Menu.Item>
          </Menu>
      </Header>
      <Content style={{ padding: '0 0px', marginLeft: 0, paddingTop: 110}}>
        <div  style={{ background: '#fff', padding: 0, paddingTop: 0, minHeight: 380 }}>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/tools" component={Tools}/>
            <Route path="/check" component={DataCheck}/>
            <Route path="/quotes" component={Quotes}/>
            <Route path="*">
              <Redirect to="/"/>
            </Route>
          </Switch>
        </div>
      </Content>
      <Footer style={{ marginLeft: 0, textAlign: 'center' }}>
        (c) 2020 OpenUrise Corporation. All Rights Reserverd
        <p>
          Build Date: {preval`module.exports = new Date().toLocaleString();`}.
        </p>
    </Footer>
  </Layout>
  );
}

export default withRouter( MainBrowswer );
