import { createSelector } from 'reselect';

import { quoteSorter } from 'lib/sorters';

const sorter = quoteSorter( 'lastUpdated', 'descending' );

const getDeletedQuotes = state => state.deleted.items;

export const selectDeletedQuotes = createSelector(
  getDeletedQuotes,
  (quotes) => ([...quotes.values()]).sort( sorter )
);

export const selectDeletedQuotesMap = createSelector(

  getDeletedQuotes,
  (quotesMap) => quotesMap
);
