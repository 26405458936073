import React from 'react';

import { List, Statistic } from 'antd';

import { useSelector } from 'react-redux';

import { selectQuotes } from 'selectors/quotes';

import { quoteSorter } from 'lib/sorters';

import QuoteListItem from 'components/QuoteListItem';

const sorter = quoteSorter( 'lastUpdated', 'descending' );


const Home = ( props ) => {

  const quotes = useSelector( selectQuotes );

  const totalNumber = quotes.length;

  const stats = {

    hidden: 0,
    review: 0,
  };

  quotes.forEach( (quote) => {

    if( quote.hide ) {

      stats.hidden++;
    }

    if( quote.review ) {

      stats.review++;
    }
  });

  const recentQuotes = [ ...quotes ].sort( sorter ).slice( 0, 5 );

  return (

    <div style={{display: 'flex', flexDirection: 'row', padding: 20}}>
      <div style={{flexGrow: 2, margin: 4, padding: 20}}>
        <h3>Recently Updated Quotes</h3>
        <List
          itemLayout="horizontal"
          dataSource={recentQuotes}
          renderItem={item => (<QuoteListItem quote={item}/>)}
        />
      </div>
      <div style={{display: 'flex', flexDirection: 'column', minWidth: 200}}>
        <Statistic title="Total Number of Quotes" value={totalNumber} style={{margin: 4, padding: 20, borderWidth: 1, borderRadius: 5, borderColor: '#EEE', borderStyle: 'solid'}}/>
        <Statistic title="Quotes Waiting for Review" value={stats.review} style={{margin: 4, padding: 20, borderWidth: 1, borderRadius: 5, borderColor: '#EEE', borderStyle: 'solid'}}/>
        <Statistic title="Quotes Hidden" value={stats.hidden} style={{margin: 4, padding: 20, borderWidth: 1, borderRadius: 5, borderColor: '#EEE', borderStyle: 'solid'}}/>
      </div>
    </div>
  );
}

export default Home;
