/**
 * Actions for Quotes Page
 */

import { createSearchAction } from 'redux-search'

export const QUOTES_PAGE_SELECTION = 'QUOTES_PAGE_SELECTION';

export function setSelectedQuotes( ids ) {

  return { type: QUOTES_PAGE_SELECTION, ids };
}

export const searchQuotes = createSearchAction( 'quotes' );
