import React, { useState, useEffect, useMemo } from 'react';

import { Menu, Layout, Card, Statistic, Row, Col, Table, Typography } from 'antd';

import CatCountPage from './CatCountPage';
import DeletedPage from './DeletedPage';
import DeploymentPage from './DeploymentPage';

const { Text, Title } = Typography;

const { Sider, Content } = Layout;

const ToolsPage = ( {} ) => {

  const [ selectedMenuItem, setSelectedMenuItem ] = useState( 'cat_counts' );

  return (
    <Layout style={{backgroundColor: 'white', paddingTop: 8}}>
      <Sider style={{backgroundColor: 'white'}}>
        <Menu
            selectedKeys={[selectedMenuItem]}
            mode="inline"
            onSelect={({key}) => setSelectedMenuItem( key )}
          >
          <Menu.Item key="cat_counts">Category Counts</Menu.Item>
          <Menu.Item key="deleted">Restore Deleted</Menu.Item>
          <Menu.Item key="deployment">Deployment</Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{backgroundColor: 'white'}}>
          { selectedMenuItem === 'cat_counts' && <CatCountPage/>}
          { selectedMenuItem === 'deleted' && <DeletedPage/>}
          { selectedMenuItem === 'deployment' && <DeploymentPage/>}
        </Content>
      </Layout>
    </Layout>
  );
}

export default ToolsPage;
