import { handleActions } from './handle-actions';

const INITIAL_STATE = {

  mode: 'none',
  ids: [],
  saving: false,
};

const reducer = handleActions( {

    QUOTE_EDITOR_START: (draft, {mode, ids}) => {

      draft.mode = mode;
      draft.ids = ids;
    },

    QUOTE_SAVE: (draft) => { draft.saving = true },
    QUOTES_SAVE: (draft) => { draft.saving = true },

    QUOTE_EDITOR_CANCEL: (draft) => INITIAL_STATE,
    QUOTE_SAVE_SUCCESSFUL: (draft) => INITIAL_STATE,

    QUOTE_SAVE_UNSUCCESSFUL: (draft) => { draft.saving = false; },

    QUOTES_UPDATED: (draft,{quotes}) => {

      const removedIds = quotes.filter( ({deleted}) => deleted ).map( ({id}) => id );

      if( removedIds.length > 0 ) {

        draft.ids = [];
        draft.mode = 'none';
        draft.saving = false;
      }
    }
  },
  INITIAL_STATE
);

export default reducer;
