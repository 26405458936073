import React, { forwardRef } from 'react';

import { Select } from 'antd';

import Category from 'components/Category';

import { categories as allCategories } from 'lib/categories';

const { Option } = Select;

const SelectCategories = forwardRef( ( { ...rest }, ref ) => {

  return (
    <Select
      mode="multiple"
      placeholder="Select categories"
      showArrow optionLabelProp="label"
      { ...rest }
      ref={ref}
    >
      {
        allCategories().map( (cat) => (

          <Option key={cat.name} value={cat.name} label={cat.name.toUpperCase()}>
            <Category name={cat.name}/>
          </Option>
        ))
      }
    </Select>
  );
});

export default SelectCategories;
