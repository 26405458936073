import React, { useState, useMemo, forwardRef } from 'react';

import { AutoComplete } from 'antd';

import { useSelector } from 'react-redux';

import { selectQuotes } from 'selectors/quotes';

function getSourcesData( quotes, field ) {

  let valueSet = new Set();

  quotes.forEach( ( q ) => {

    const value = q[ field ];

    if( value ) {

      valueSet.add( value.trim() );
    }
  });

  const values = Array.from( valueSet )
    .sort( (a,b) => a.localeCompare( b ) )
    .map( (s) => [ s.toLowerCase(), s ] );

  return values;
}

const QuoteAutoComplete = forwardRef( ( { field = 'source', ...rest }, ref ) => {

  const allQuotes = useSelector( selectQuotes );

  const allValues = useMemo( () => getSourcesData( allQuotes, field ), [allQuotes, field ] );

  const [ matchValues, setMatchValues ] = useState( [] );

  const onSearch = ( searchText ) => {

      const dataSource = [];

      if( searchText.length > 0 ) {

        const text = searchText.toLowerCase();

        for( let i = 0; i < allValues.length; i++ ) {

          if( allValues[i][0].startsWith( text ) ) {

            dataSource.push( allValues[i][1] );
          }
        }
      }


      setMatchValues( dataSource.map( (value) => ({value})) );
    };

    return (<AutoComplete ref={ref} options={matchValues} onSearch={onSearch} {...rest}/>);
});

export default QuoteAutoComplete;
