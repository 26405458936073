import { handleActions } from './handle-actions';

import { quoteSorter } from 'lib/sorters';

const sorter = quoteSorter( 'id' );

const INITIAL_STATE = {

  loading: false,
  items: new Map(),
  version: 0,
}

const processAdd = ( itemsMap, quotes ) => {

  quotes.filter( ({id, deleted}) => !deleted && !itemsMap.has( id ))
    .sort( sorter )
    .forEach( (item) => {

      itemsMap.set( item.id, { ...item, index: itemsMap.size } );
    });
};

const processUpdate = ( itemsMap, quotes ) => {

  quotes.filter( ({id, deleted}) => !deleted && itemsMap.has( id ))
    .forEach( (item) => {

      const existing = itemsMap.get( item.id );

      itemsMap.set( item.id, { ...item, index: existing.index } );
    });
};

const processRemoved = ( itemsMap, quotes ) => {

  const removed = quotes.filter( ({deleted}) => deleted );

  if( removed.length > 0 ) {

    for( const {id} of removed ) {

      itemsMap.delete( id );
    }

    // re-index
    let index = 0;

    for( const item of itemsMap.values() ) {

        item.index = index++;
    }
  }
};

const reducer = handleActions( {

  QUOTES_LOAD: (draft, payload) => { draft.loading = true; },

  QUOTES_LOADED: (draft, { quotes }) => {

      const newItems = [...quotes].filter( ({deleted}) => !deleted ).sort( sorter );

      draft.loading = false;
      draft.items = new Map( newItems.map( (quote, index) => ([quote.id,{ ...quote, index }])) );
    },

  QUOTES_UPDATED: (draft, { quotes }) => {

    processAdd( draft.items, quotes );
    processUpdate( draft.items, quotes );
    processRemoved( draft.items, quotes );
  },

}, INITIAL_STATE );

export default reducer;
