import React, { Suspense, useEffect } from 'react';

import { isMobileOnly } from 'react-device-detect';

import { useDispatch } from 'react-redux'

import './App.css';

import { loadQuotes } from 'actions/quotes';

import { getCurrentAuthenticatedUser } from 'actions/amplify';

import Amplify, { Auth } from 'aws-amplify';
import '@aws-amplify/ui/dist/style.css';

//import awsconfig from './aws-exports';
import { withAuthenticator, Greetings, SignIn, ConfirmSignIn, VerifyContact, ForgotPassword, RequireNewPassword } from 'aws-amplify-react'; // or 'aws-amplify-react-native';

import BrowserApp from './BrowserApp';
import MobileApp from './MobileApp';

Amplify.configure({

  Auth: {

    identityPoolId: 'us-east-1:003d8835-983b-48ff-af0b-a33c816382a5',
    region: 'us-east-1',
    userPoolId: 'us-east-1_HLPR9Z7xS',
    userPoolWebClientId: '2p9mf490tl6hmb1a5q29o5vg8a',
  },

  API: {
    endpoints: [
        {
            name: 'KQManagerAPI',
            endpoint: 'https://kq-manager-api.openurise.com',
            custom_header: async () => {

              return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
        }
    ],
  }
});

// Don't use lazy at the moment - issue with Amplify API - looks like a race condition
// const BrowserApp = React.lazy(() => import( './BrowserApp') );
// const MobileApp = React.lazy(() => import( './MobileApp') );

function App( { authState, authData } ) {

  const dispatch = useDispatch();

  useEffect( () => {

    dispatch( getCurrentAuthenticatedUser() );
  }, [authData, dispatch ]);

  useEffect( () => {

    dispatch( loadQuotes() );
  }, [ dispatch ]);

  const mainProps = { authData };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isMobileOnly ? <MobileApp {...mainProps}/> : <BrowserApp {...mainProps }/>}
    </Suspense>
  );
}

export default withAuthenticator( App, false, [

    <Greetings/>,
    <SignIn/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <ForgotPassword/>,
    <RequireNewPassword/>,
  ]);
