import React from 'react';

import {  Avatar } from 'antd';
import * as antColors from '@ant-design/colors';

import { getColorName } from 'lib/categories';

const CategoryAvatar = ({quote}) => {

  const catName = (quote.categories || [])[0] || '';

  const colorName = getColorName( catName );

  const antColor = antColors[ colorName ];

  const backgroundColor = antColor ? antColor[1] : '#EEE';
  const color = antColor ? antColor[6] : '#444';

  return (
    <Avatar style={{ color, backgroundColor }}>
      {quote.index + 1}
    </Avatar>);
}

export default CategoryAvatar;
