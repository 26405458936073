export const AMPLIFY_GET_CURRENT_AUTH_USER = 'AMPLIFY_GET_CURRENT_AUTH_USER';

export const AMPLIFY_SET_CURRENT_AUTH_USER = 'AMPLIFY_SET_CURRENT_AUTH_USER';

export const AMPLIFY_CLEAR = 'AMPLIFY_CLEAR';

export function getCurrentAuthenticatedUser() {

  return { type: AMPLIFY_GET_CURRENT_AUTH_USER };
};

export function setCurrentAuthenticatedUser( data ) {

  const { attributes } = data;

  const groups = data.signInUserSession.accessToken.payload["cognito:groups"];

  return { type: AMPLIFY_SET_CURRENT_AUTH_USER, attributes, groups };
}

export function clearCurrentUser() {

  return { type: AMPLIFY_CLEAR };
}
