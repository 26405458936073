import React, { useMemo } from 'react';

import { Button, Statistic, Table, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';

import { loadQuotes } from 'actions/quotes';

import { setSelectedQuotes } from 'actions/quotes-page';

import { startQuoteEdit, startQuoteEditMultiple } from 'actions/quote-editor';

import { selectQuotesLoading, selectQuotes } from 'selectors/quotes';

import { selectGetQuoteEditMode } from 'selectors/quote-editor';

import { EditQuoteDrawer } from 'drawers';

import columns from './columns';

import { checkData } from './datachecker';

import './DataCheck.css';

const DataCheck = () => {

  const dispatch = useDispatch();

  const loading = useSelector( selectQuotesLoading );

  const quotes = useSelector( selectQuotes );

  const editMode = useSelector( selectGetQuoteEditMode );

  const { issues, counts } = useMemo( () => checkData( quotes ), [quotes] );

  const editQuote = (ids) => {

    if( Array.isArray( ids ) ) {

      if( ids.length > 1 ) {

        return dispatch( startQuoteEditMultiple( ids ) );
      }

      ids = ids[0];
    }

    if( ids ) {

      dispatch( startQuoteEdit( ids ) );
    }
  };

  return (

    <div style={{paddingTop: 8, paddingLeft: 8, paddingRight: 8}}>
      <div style={{paddingBottom: 0 }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Tag className="data-check-count" color='red'>{`Errors: ${counts.error}`}</Tag>
          <Tag className="data-check-count" color='orange'>{`Warnings: ${counts.warning}`}</Tag>
          <Tag className="data-check-count" color='blue'>{`Info: ${counts.info}`}</Tag>
          <Button
            style={{marginRight: 10}}
            icon={<ReloadOutlined/>}
            onClick={()=> {

              dispatch( loadQuotes() );
            }}
            disabled={loading}
            loading={loading}
          >
          </Button>
        </div>
      </div>
      <Table
        dataSource={issues}
        columns={columns}
        pagination={{position: 'bottom',defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10','20','50','100']}}
        size="small"
        bordered={false}
        style={{ background: '#fff', minHeight: 280, paddingTop: 20 }}
        rowKey={record => record.type + record.quote.id}
        loading={loading}
        scroll={{ y: true }}
        onChange={() => dispatch( setSelectedQuotes( [] ))}
        onRow={ (record, rowIndex) => {
          return {
            onDoubleClick: event => {

              editQuote( record.quote.id );
            }
          };
        }}
      />

    { editMode === 'edit' && <EditQuoteDrawer/>}
    </div>
  );
}

export default DataCheck;
