import { combineReducers } from 'redux';

import { reducer as searchReducer } from 'redux-search'

import quotes from './quotes';
import quote_editor from './quote-editor';
import quotes_page from './quotes-page';
import amplify from './amplify';
import deleted from './deleted';
import deployment from './deployment';

const appReducers = () => combineReducers({
  search: searchReducer,
  quotes,
  deleted,
  deployment,
  quote_editor,
  quotes_page,
  amplify,
});

export default appReducers;
