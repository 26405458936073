import { createSelector } from 'reselect';

import { getSearchSelectors } from 'redux-search'

const getQuotes = state => state.quotes.items;

const getDeletedQuotes = state => state.deleted.items;

const getLoading = state => state.quotes.loading;

export const selectAllQuotesMap = createSelector(

  getQuotes,
  getDeletedQuotes,
  (quotesMap, deletedQuotesMap) => {

    return new Map( [ ...quotesMap.entries(), ...deletedQuotesMap.entries() ])
  }
);

export const selectQuotesMap = createSelector(

  getQuotes,
  (quotesMap) => quotesMap
);

export const selectQuotes = createSelector(
  getQuotes,
  (quotes) => ([...quotes.values()])
);

export const selectQuotesLoading = createSelector(

  getLoading,
  (loading) => loading
);

const { text: getSearchText, result: getSearchIds } = getSearchSelectors({

    resourceName: 'quotes',
    resourceSelector: (resourceName, state) => state.quotes.items
  });

export const selectQuotesWithSearch = createSelector(
  getSearchText,
  getSearchIds,
  getQuotes,
  (searchText, visibleIds, allQuotes) => {

    const quotes = [];

    visibleIds.forEach( (id) => quotes.push( allQuotes.get( id ) ) );

    quotes.sort( (a,b) => a.id.localeCompare( b.id ) );

    return {

      searchText,
      visibleIds,
      quotes
    };
  }
);


//export const quotesSelector = (resourceName, state) => state.resources.get(resourceName)
