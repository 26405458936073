import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { selectIsUserAdmin } from 'selectors/amplify';

import { NavigationBar, Page, PageContent } from 'mobile-components';

import QuoteEdit from './QuoteEdit';

import { useSaveQuote } from './save-quote';

import { hasChanged, showDiscardActionSheet, validate } from './helper';

const QUOTE_INITIAL = { quote: '', source: '', origSource: '', categories: [], review: false, hide: false, };

const AddQuotePage = ({history}) => {

  const isAdmin = useSelector( selectIsUserAdmin );

  const goBack = () => { history.replace( '/quotes' ); };

  const [quote, setQuote] = useState( { ...QUOTE_INITIAL } );

  const updateQuote = (changes) => setQuote( {...quote, ...changes } );

  const saveQuote = useSaveQuote( goBack );

  const handleDone = () => {

    if( validate( quote ) ) {

      saveQuote( null, quote );
    }
  }

  const onLeftClick = () => {

    if( !hasChanged( QUOTE_INITIAL, quote ) ) {

      return goBack();
    }

    showDiscardActionSheet( goBack );
  }

  return (
    <Page>
      <NavigationBar
        type="none"
        title="Add Quote"
        leftContent="Cancel"
        onLeftClick={onLeftClick}
        rightContent={
          <div role="button" onClick={handleDone}>
            Done
          </div>}
      />
      <PageContent>
        <QuoteEdit quote={quote} onChange={updateQuote} isAdmin={isAdmin}/>
        <div style={{height:32}}/>
      </PageContent>
    </Page>
  );
}

export default AddQuotePage;
